.container {
    width: 1200px;
    margin: 0 auto;

    @media (max-width: $md3+px) {
        width: 100%;
        padding: 0 40px;
    }

    @media (max-width: 600px) {
        padding: 0 18px;
    }
}