@import '../../../styles/var';

.button {
  display: inline-flex;
  padding: 16px 35px;
  border-radius: 50px;
  color: #f1edee;
  text-align: center;
  font-family: $roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  cursor: pointer;
  text-align: center;
  transition: all .3s;
  text-transform: uppercase;

  border-radius: 74px;
  border: 1px solid #918F90;
  background: linear-gradient(90deg, #2C08FF 0%, #A535FF 100%);
  box-shadow: 0px 0px 0.25px 1.25px #262524 inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1px 1px 3px 3px #1A1818 inset;

  @media(max-width: $md4+px) {
    font-size: 16px;
    padding: 16px 30px;
  }

  @media(max-width: $md6+px) {
    font-size: 14px;
  }
  
  &:hover {
    box-shadow: 0px 0px 20px 5px #262524 inset;
  }

  &:active {
    transform: scale(0.97);
    transition: all .1s;
    box-shadow: 0px 0px 40px 10px #262524 inset;
  }

  &.secondary {
    border: 0.5px solid #918F90;
    background: linear-gradient(90deg, #FF0D47 0%, #CA35FF 100%);
  }

  &.dark {
    border: 0.5px solid #918F90;
    background: linear-gradient(90deg, #202020 0%, #2D2942 100%);
  
    &:hover {
      box-shadow: 0px 0px 20px 5px #3d3c3b inset;
    }
  }

  &.paddingSm {
    padding: 9px 20px;
  }
}
