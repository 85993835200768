@import "./_var.scss";
@import './mixins';

body,
html {
    background: #000;
    color: #fff;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        background: #9C32FF;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #b771f8;
    }
}

.font-190 {
    color: #FFF;
    font-family: $roboto;
    font-size: 190px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 150px;
    }

    @media(max-width: $md4+px) {
        font-size: 100px;
    }

    @media(max-width: $md6+px) {
        font-size: 50px;
    }

    span {
        color: $violet;
    }
}

.font-190-yl {
    color: #FFF;
    font-family: $roboto;
    font-size: 190px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 120px;
    }

    @media(max-width: $md6+px) {
        font-size: 70px;
    }

    span {
        color: $violet;
    }
}

.font-190-wall {
    color: $violet;
    font-family: $roboto;
    font-size: 190px;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 150px;
        line-height: 108%;
    }

    @media(max-width: $md4+px) {
        font-size: 100px;
    }

    @media(max-width: $md6+px) {
        font-size: 50px;
        line-height: 100%;
    }
}

.font-160-yl {
    color: #FFF;
    font-family: $roboto;
    font-size: 160px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 120px;
    }

    @media(max-width: $md6+px) {
        font-size: 70px;
    }

    span {
        color: $violet;
    }
}

.font-140 {
    color: #FFF;
    font-family: $krona;
    font-size: 140px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 12.5px;
    text-transform: uppercase;
    margin-left: -12px;

    @media(max-width: $md1+px) {
        font-size: 120px;
    }

    span {
        color: $violet;
    }
}

.font-120 {
    color: #FFF;
    font-family: $krona;
    font-size: 120px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md1+px) {
        font-size: 100px;
    }

    @media(max-width: $md3+px) {
        font-size: 90px;
    }

    @media(max-width: $md4+px) {
        font-size: 80px;
    }

    @media(max-width: $md5+px) {
        font-size: 60px;
    }

    @media(max-width: $md6+px) {
        font-size: 50px;
        letter-spacing: -3px;
    }
}

.font-100 {
    color: #FFF;
    font-family: $roboto;
    font-size: 100px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 21px;
    text-transform: uppercase;
    padding-left: 21px;

    @media(max-width: $md4+px) {
        font-size: 72px;
        letter-spacing: 15px;
        padding-left: 15px;
    }

    @media(max-width: $md5+px) {
        font-size: 50px;
        letter-spacing: 10px;
        padding-left: 10px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
        letter-spacing: 7px;
        padding-left: 7px;
        font-weight: 500;
    }

    span {
        color: $violet;
    }
}

.font-96 {
    color: #CE9AFF;
    font-family: $roboto;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-80 {
    color: $violet;
    font-family: $roboto;
    font-size: 80px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 60px;
    }

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-75 {
    color: #FFF;
    font-family: $roboto;
    font-size: 75px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 60px;
    }

    @media(max-width: $md4+px) {
        font-size: 45px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-75-st {
    color: #FFF;
    font-family: $roboto;
    font-size: 75px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;
    letter-spacing: 11.25px;

    @media(max-width: $md4+px) {
        font-size: 60px;
        letter-spacing: 8px;
    }

    @media(max-width: $md6+px) {
        font-size: 40px;
        letter-spacing: 6px;
    }

    span {
        color: $violet;
    }
}

.font-75-net {
    color: rgba(156, 50, 255, 1);
    font-family: $roboto;
    font-size: 40px;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 7.5px;

    @media(max-width: $md4+px) {
        font-size: 50px;
        letter-spacing: 5px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 2px;
        -webkit-text-stroke: 0.8px $violet;
    }
}

.font-75-sponsors {
    color: rgba(255, 255, 255, 0.50);
    font-family: roboto;
    font-size: 74.259px;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 60px;
    padding-left: 60px;

    @media(max-width: $md4+px) {
        font-size: 50px;
        letter-spacing: 40px;
        padding-left: 40px;
    }

    @media(max-width: $md5+px) {
        font-size: 40px;
        letter-spacing: 30px;
        padding-left: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 20px;
        padding-left: 20px;
    }
}

.font-70 {
    color: #FFF;
    font-family: $roboto;
    font-size: 70px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 55px;
    }

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }
}

.font-70-d {
    color: #FFF;
    font-family: $roboto;
    font-size: 70px;
    font-weight: 700;
    line-height: 120%;

    @media(max-width: $md3+px) {
        font-size: 55px;
    }

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }
}


.font-70-vip {
    color: #FFF;
    font-family: $roboto;
    font-size: 70px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 50px;
    }

    @media(max-width: 600px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }
}

.font-60 {
    color: #FFF;
    font-family: $roboto;
    font-size: 60px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 18px;
    text-transform: uppercase;
    padding-left: 18px;

    @media(max-width: $md4+px) {
        font-size: 40px;
        letter-spacing: 12px;
        padding-left: 12px;
    }

    @media(max-width: $md5+px) {
        font-size: 26px;
        letter-spacing: 8px;
        padding-left: 8px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
        letter-spacing: 5px;
        padding-left: 5px;
    }
}

.font-55 {
    color: #FFF;
    font-family: $roboto;
    font-size: 55px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;
    letter-spacing: 2.7px;

    @media(max-width: $md4+px) {
        font-size: 40px;
        letter-spacing: 2px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
        letter-spacing: 1px;
    }

    span {
        background: linear-gradient(90deg, #F81154 38.84%, #9D32FB 99.99%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.font-50 {
    color: #FFF;
    font-family: $roboto;
    font-size: 50px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }

    span {
        color: $violet;
    }
}

.font-48 {
    color: #CE9AFF;
    font-family: $bayon;
    font-size: 48px;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-40 {
    color: #9C32FF;
    font-family: $roboto;
    font-size: 40px;
    font-weight: 500;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 22px;
    }
}

.font-40-krona {
    color: #9C32FF;
    font-family: $krona;
    font-size: 40px;
    font-weight: 500;
    line-height: 150%;

    @media(max-width: $md3+px) {
        font-size: 30px;
    }

    @media(max-width: $md4+px) {
        font-size: 25px;
    }

    @media(max-width: 800px) {
        font-size: 20px;
    }

    @media(max-width: 650px) {
        font-size: 25px;
    }
}

.font-40-banner-krona {
    color: #FFF;
    font-family: $krona;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 10px;

    @media(max-width: $md4+px) {}

    @media(max-width: 600px) {
        font-size: 27px;
        letter-spacing: 6px;
        padding-left: 3px;
    }

    span {
        color: $violet;
    }
}

.font-36 {
    color: #FFF;
    font-family: $roboto;
    font-size: 36px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 11px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 30px;
        letter-spacing: 9px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 7px;
    }
}

.font-35-donate {
    color: #FFF;
    font-family: $roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 22px;
        letter-spacing: 0.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-35-yl {
    color: #FFF;
    font-family: $roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.7px;

    @media(max-width: $md4+px) {
        font-size: 20px;
        letter-spacing: 0.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-35-tags {
    color: #FFF;
    font-family: $roboto;
    font-size: 35px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3.5px;

    @media(max-width: $md4+px) {
        font-size: 24px;
        letter-spacing: 2px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
        letter-spacing: 1px;
    }
}

.font-34 {
    color: #FFF;
    font-family: $roboto;
    font-size: 34px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 1.7px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 25px;
        letter-spacing: 1.2px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 1px;
    }

    span {
        color: $violet;
    }
}

.font-30-krona {
    color: #FFF;
    font-family: $krona;
    font-size: 30px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 2.5px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 22px;
    }

    span {
        color: $violet;
    }
}

.font-30-banner-ct-krona {
    color: #FFF;
    font-family: $krona;
    font-size: 30px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-left: -2px;

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-30 {
    color: #FFF;
    font-family: $roboto;
    font-size: 30px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;

    @media(max-width: $md4+px) {
        font-size: 18px;
        letter-spacing: 1.8px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 1.5px;
    }

    span {
        color: $violet;
    }
}

.font-30-popup {
    color: #260E3C;
    font-family: $roboto;
    font-size: 30px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;

    @media(max-width: $md4+px) {
        font-size: 24px;
        letter-spacing: 2px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
        letter-spacing: 1px;
    }

    span {
        color: $violet;
    }
}

.font-30-tickets {
    color: #FFF;
    font-family: $roboto;
    font-size: 30px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;

    @media(max-width: $md4+px) {
        font-size: 24px;
        letter-spacing: 1.8px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 1.5px;
    }

    span {
        color: $violet;
    }
}

.font-30-themes {
    color: #FFF;
    font-family: $roboto;
    font-size: 30px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        color: $violet;
    }
}

.font-28-banner-secret {
    color: #9C32FF;
    font-family: $krona;
    font-size: 28px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 5px;
    writing-mode: vertical-rl;
    text-orientation: upright;

    @media(max-width: $md4+px) {
        font-size: 40px;
        letter-spacing: 10px;
        writing-mode: initial;
        text-orientation: initial;
    }

    @media(max-width: $md5+px) {
        color: #fff;
    }

    @media(max-width: 600px) {
        font-size: 27px;
        letter-spacing: 6px;
    }
}

.font-26 {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 16px;
    }
}

.font-25 {
    color: #FFF;
    font-family: $roboto;
    font-size: 25px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.75px;

    @media(max-width: $md4+px) {
        letter-spacing: initial;
        font-size: 20px;
    }
    
    @media(max-width: $md6+px) {
        letter-spacing: initial;
        font-size: 18px;
    }
}

.font-23-banner {
    color: #FFF;
    font-family: $roboto;
    font-size: 30px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 6px;

    @media(max-width: $md4+px) {
        font-size: 18px;
        letter-spacing: 3.5px;
    }

    @media(max-width: $md5+px) {
        font-size: 14px;
        letter-spacing: 2.5px;
    }
}

.font-22 {
    color: #FFF;
    font-family: $roboto;
    font-size: 22px;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-marquee {
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }

    span {
        color: #CF9CFF;
    }
}

.font-20-banner-ct-krona {
    color: #FFF;
    font-family: $krona;
    font-size: 20px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 2.5px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}

    span {
        color: $violet;
    }
}

.font-20-krona {
    color: #FFF;
    font-family: $krona;
    font-size: 20px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        letter-spacing: 1.5px;
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 1px;
    }
}

.font-20-bel {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-20-vip {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0.6px;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md5+px) {
        font-size: 14px;
    }

    b {
        font-weight: 500;
    }
}

.font-20 {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 2px;

    @media(max-width: $md4+px) {
        font-size: 18px;
        letter-spacing: 1.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20-banner-content-1 {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    font-variant: small-caps;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }

    b {
        color: $violet;
        font-weight: 700;
    }
}

.font-25-banner-content {
    color: #FFF;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md3+px) {
        font-size: 22px;
    }

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }

    b {
        color: $violet;
        font-weight: 700;
    }
}

.font-25-banner-sub {
    color: #FFF;
    font-family: $roboto;
    font-size: 25px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 20px;
    }

    @media(max-width: $md5+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 0;
    }
}

.font-20-banner-speaker {
    color: #FC1052;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.8px;

    @media(max-width: $md4+px) {
        font-size: 18px;
        letter-spacing: 0.6px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 0.5px;
    }
}

.font-16 {
    color: #FFF;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16-footer {
    color: #000;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16-ticket-sub {
    color: #CE9AFF;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 1.6px;

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 1px;
    }
}

.font-16-ticket {
    color: #FFF;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.5px;

    @media(max-width: $md6+px) {
        font-size: 12px;
    }
}

.mt {
    margin-top: 180px;

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.violet {
    color: $violet !important;
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.word {
    position: absolute;
    cursor: grab;
    font-size: 30px;
    color: #fff;
    user-select: none;
    display: inline-flex;
    padding-left: 5px;
}

.c-140224 {
    color: #140224;
}

.c-CE9AFF {
    color: #CE9AFF;
}

.c-fff {
    color: #fff;
}