@import '../../../styles/var';

.popupWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    // background: rgba(0, 0, 0, 0.20);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);

    opacity: 0;
    pointer-events: none;
    transition: all .3s;

    &.visible {
        opacity: 1;
        pointer-events: visible;

        .popup {
            transform: translateY(0);
        }
    }
}

.closeZone {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.container {
    position: relative;
    height: 100%;
    display: flex;
    align-self: center;
    justify-content: center;

    @media (max-width: 600px) {
        // padding: 0 !important;
        // margin: 0 !important;
    }
}

.popup {
    position: relative;
    z-index: 2;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.80);
    margin: auto;
    padding: 60px 60px;
    text-align: center;
    width: 100%;
    max-width: 550px;

    transform: translateY(-50px);
    transition: all .3s;

    @media(max-width: $md5+px) {
        max-width: 100%;
        padding: 60px 40px;
        width: 100%;
    }

    @media(max-width: 600px) {
        padding: 50px 18px 40px;
    }

    @media(max-width: $md6+px) {
        padding: 40px 18px;
    }

    .closeIcon {
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 25px;
        cursor: pointer;
        width: 24px;
        height: 24px;

        @media(max-width: $md6+px) {
            top: 10px;
            right: 10px;
        }

        img {
            width: 100%;
        }
    }
}